<template>
  <div>
    <div ref="editor"
         style="text-align:left;z-index:1"></div>
  </div>
</template>

<script>
import E from 'wangeditor'
import {
  getCos,
  imgUpload
} from '@/api'
import axios from 'axios'
export default {
  name: 'editor',
  props: {
    value: String
  },
  data () {
    return {
      isChange: false,
      editor: null,
      imgData: {},
      prefix: '',
      imgPostData: {},
      loading: null,
      info_: null
    }
  },
  methods: {
    // 计算签名
    async getAuthorization () {
      const res = await getCos({
        type: 'ACTIVITY_PICTURES'
      })
      if (res.meta.code === 0) {
        this.imgPostData = res.data
        // callback(res.data);
        const AuthData = res.data
        this.prefix = 'https://' + AuthData.Bucket + '.cos.' + AuthData.Region + '.myqcloud.com/'
        this.imgData = {
          Key: AuthData.FilePath + '/' + AuthData.Key,
          Signature: AuthData.Authorization,
          'Content-Type': '',
          'x-cos-security-token': AuthData.XCosSecurityToken
        }
      }
    }
  },
  computed: {

  },
  watch: {
    // value: {
    //   immediate: true, // 这句重要
    //   handler (val) {
    //     this.editor.txt.html(val)
    //   }
    // },
    value (val) {
      if (!this.isChange) {
        this.editor.txt.html(val)
      }
      this.isChange = false
    },
    editor () {
      this.editor.txt.html(this.value)
    }

  },
  async mounted () {
    this.editor = new E(this.$refs.editor)

    this.editor.customConfig.zIndex = 1
    this.editor.customConfig.onchange = (html) => {
      // this.editorContent = html
      this.isChange = true
      this.info_ = html // 绑定当前逐渐地值
      this.$emit('editor-change', this.info_)
      this.$emit('change', this.info_)
      this.$emit('input', this.info_)
    }
    const self = this
    this.editor.customConfig.customAlert = function (info) {
      // info 是需要提示的内容
      self.$message.error(info)
    }
    this.editor.customConfig.uploadImgMaxSize = 500 * 1024
    this.editor.customConfig.uploadImgServer = '/upload'
    this.editor.customConfig.customUploadImg = async function (files, insert) {
      // 显示loading
      self.loading = self.$loading({
        lock: true,
        text: '图片上传中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      // 等待签名
      await self.getAuthorization()
      const imgData = self.imgData

      const formData = new FormData()
      formData.append('Key', imgData.Key)
      formData.append('Signature', imgData.Signature)
      formData.append('Content-Type', 'multipart/form-data;')
      formData.append('x-cos-security-token', imgData['x-cos-security-token'])
      formData.append('file', files[0])

      // 发送
      const headers = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      const instance = axios.create()
      const res = await instance.post(self.prefix, formData, headers)
      if (res.status >= 200 && res.status < 300) {
        const imgObject = await imgUpload({
          type: 'ACTIVITY_PICTURES',
          key: self.imgPostData.Key,
          path: self.imgPostData.FilePath,
          driver: 'COS'
        })
        if (imgObject.meta.code === 0) {
          self.loading.close()
          insert(imgObject.data.images[0].url)
        } else {
          self.$message.error('上传失败')
        }
      }
    }

    this.editor.create()
    // this.editor.txt.html(this.editorContent)
    this.$on('set-editor-value', function (html) {
      this.editor.txt.html(html)
    })
  }
}
</script>

<style scoped>
.w-e-text-container {
  z-index: 1 !important;
}
</style>
